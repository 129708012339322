<template>
  <div class="rule">
    <page-block :title="$t('alg.title')" class="rule-block">
      <tumbler
        slot="afterTitle"
        :value="tumbler"
        class="theme-switcher__tumbler"
        @change="setState"
      />
      <base-loader v-if="loading && !isReady && type === 'edit'" class="base-btn__loader" />
      <main-card v-else class="main-card main-card--medium">
        <template #header>
          {{ $t('text') }}
        </template>
        <template #headerAction>
          <!--          <tumbler :value="tumbler" class="theme-switcher__tumbler" @change="setState" />-->
        </template>
        <div class="security-password__form">
          <div class="security-password__field">
            <base-select
              v-model="healthmonitor.type"
              :options="healthType"
              :searchable="false"
              :allow-empty="false"
              :custom-label="refactorLabels"
              open-direction="top"
              :max-height="type === 'edit' ? 300 : 180"
              field-label="Проверка"
              :disabled="type === 'edit' || !tumbler"
              class="select"
              :show-labels="false"
              @input="onChangeCheck(healthmonitor.type, $event)"
            />
          </div>
          <div class="security-password__field">
            <label class="base-select__label bottom standart-title">С интервалом, c</label>
            <InputNumber
              v-model="healthmonitor.delay"
              show-buttons
              mode="decimal"
              suffix=" c"
              :min="1"
              :max="100000"
              :disabled="!tumbler"
              class="p-inputtext-lg"
              button-layout="horizontal"
              :input-style="{ 'text-align': 'center', 'max-width': '4rem' }"
              decrement-button-class="p-button-blue"
              increment-button-class="p-button-blue"
              increment-button-icon="pi pi-plus"
              decrement-button-icon="pi pi-minus"
              aria-describedby="value2-help"
            />
          </div>
          <div class="security-password__field">
            <label class="base-select__label bottom standart-title">Таймаут соединения, c</label>
            <InputNumber
              v-model="healthmonitor.timeout"
              show-buttons
              mode="decimal"
              suffix=" c"
              :min="1"
              :max="100000"
              :disabled="!tumbler"
              class="p-inputtext-lg"
              button-layout="horizontal"
              :input-style="{ 'text-align': 'center', 'max-width': '4rem' }"
              decrement-button-class="p-button-blue"
              increment-button-class="p-button-blue"
              increment-button-icon="pi pi-plus"
              decrement-button-icon="pi pi-minus"
              aria-describedby="value2-help"
            />
          </div>
        </div>
        <div v-if="healthmonitor.type === 'HTTP'">
          <div class="security-password__form">
            <div class="security-password__field">
              <base-select
                v-model="healthmonitor.http_method"
                :options="healthMethod"
                :searchable="false"
                open-direction="above"
                :disabled="!tumbler"
                :max-height="type === 'edit' ? 300 : 200"
                :allow-empty="false"
                field-label="Метод HTTP"
                class="select"
                :show-labels="false"
                @change="onPeriodChange"
              />
            </div>

            <div class="security-password__field">
              <base-input
                v-model="healthmonitor.url_path"
                :readonly="!tumbler"
                type="text"
                label="URL"
              >
                <!--              @input="onChange('url_path', $event)"-->
              </base-input>
            </div>
          </div>
          <div class="security-password__form">
            <div class="security-password__field">
              <base-input
                v-model="healthmonitor.expected_codes"
                :hint="'Одно значение: 200<br>Список значений: 200, 202<br>Диапазон значений: 200&#8209;204'"
                type="text"
                :readonly="!tumbler"
                label="Ожидаемые коды ответа"
              >
              </base-input>
              <!--              @input="onChange('expected_codes', $event)"-->
            </div>
          </div>
        </div>
        <div class="security-password__form">
          <div class="security-password__field">
            <base-select
              v-model="healthmonitor.max_retries"
              :options="connections"
              :searchable="false"
              :disabled="!tumbler"
              :allow-empty="false"
              field-label="Порог успеха"
              open-direction="above"
              :max-height="type === 'edit' ? 300 : 200"
              :custom-label="nameImage"
              class="select"
              :show-labels="false"
              @change="onPeriodChange"
            />
          </div>
          <div class="security-password__field">
            <div class="security-password__field">
              <base-select
                v-model="healthmonitor.max_retries_down"
                :options="connections"
                :searchable="false"
                :allow-empty="false"
                field-label="Порог неуспеха"
                :disabled="!tumbler"
                open-direction="above"
                :max-height="200"
                :custom-label="nameImage"
                class="select"
                :show-labels="false"
                @change="onPeriodChange()"
              />
            </div>
          </div>
        </div>
        <div
          v-if="hasChanges && type === 'edit' && isReady"
          slot="footerEnd"
          class="rule-block__actions"
        >
          <base-button class="rule-block__btn" @click="updateHealthMonitor()">
            {{ $t('alg.btn.save') }}
          </base-button>
          <base-button theme="outlined" class="rule-block__btn" @click="cancelHealthMonitor()">
            {{ $t('alg.btn.cancel') }}
          </base-button>
        </div>
      </main-card>
    </page-block>
  </div>
</template>

<script>
import BaseSelect from '@/components/Select/BaseSelect';
import InputNumber from 'primevue/inputnumber';
import BaseInput from '@/components/BaseInput/BaseInput';
import Tumbler from '@/components/BaseTumbler/Tumbler';
import { BillMgrTools } from '@/models/BillMgr/Tools';
import { OPTariff } from '@/models/OP/OPTariff';
import showErrorModal from '@/mixins/showErrorModal';
export default {
  name: 'RuleCheck',
  components: { BaseInput, InputNumber, BaseSelect, Tumbler },
  mixins: [showErrorModal],
  props: {
    ruleid: {
      type: String,
      default: '',
    },
    protocol: {
      type: String,
      default: '',
    },
    proxy: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'edit',
      validator: val => ['edit', 'create'].includes(val),
    },
    dataset: {
      type: Array,
      default: () => [],
    },
    rule: {
      type: Object,
      default: () => {},
    },
    id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      formData: {},
      initialBackup: {},
      isReady: false,
      loading: true,
      tumbler: true,
      items: [
        'delay',
        'timeout',
        'max_retries',
        'max_retries_down',
        'http_method',
        'expected_codes',
        'url_path',
        'type',
      ],
      healthmonitor: {
        delay: 5,
        timeout: 3,
        max_retries: 7,
        max_retries_down: 5,
        http_method: 'GET',
        expected_codes: '200',
        url_path: '/',
        type: this.protocol,
      },
      connections: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    };
  },
  computed: {
    healthType() {
      if (this.type === 'edit') {
        return this.$store.state.moduleStack.healthType;
      } else {
        if (this.protocol === 'TCP' && !this.proxy) {
          return ['TCP', 'PING'];
        } else if (this.protocol === 'TCP' && this.proxy) {
          return ['TCP', 'HTTP', 'TLS-HELLO', 'PING'];
        } else if (this.protocol === 'UDP') {
          return ['UDP-CONNECT', 'PING'];
        } else if (this.protocol === 'HTTP') {
          return ['HTTP', 'TCP', 'PING'];
        } else {
          // } else if (this.protocol === 'HTTPS') {
          return ['HTTP', 'TCP', 'PING'];
        }
      }
    },
    healthMethod() {
      return this.$store.state.moduleStack.healthMethod;
    },
    hasChanges() {
      // console.log(this.isReady, this.formData);
      if (this.isReady && this.formData)
        // console.log(
        //   this.healthmonitor.max_retries_down,
        //   this.healthmonitor.max_retries_down !== this.formData.max_retries_down,
        //   this.formData.max_retries_down
        // );
        // console.log(
        //   this.healthmonitor.expected_codes,
        //   this.healthmonitor.expected_codes !== this.formData.expected_codes,
        //   this.formData.expected_codes
        // );
        // console.log(
        //   this.healthmonitor.url_path,
        //   this.healthmonitor.url_path !== this.formData.url_path,
        //   this.formData.url_path
        // );
        // console.log(
        //   this.healthmonitor.http_method,
        //   this.healthmonitor.http_method !== this.formData.http_method,
        //   this.formData.http_method
        // );
        // console.log(
        //   this.healthmonitor.timeout,
        //   this.healthmonitor.timeout !== this.formData.timeout,
        //   this.formData.timeout
        // );
        // console.log(
        //   this.healthmonitor.delay,
        //   this.healthmonitor.delay !== this.formData.delay,
        //   this.formData.delay
        // );
        // console.log(
        //   this.healthmonitor.max_retries,
        //   this.healthmonitor.max_retries !== this.formData.max_retries,
        //   this.formData.max_retries
        // );
        return (
          this.healthmonitor.max_retries_down !== this.formData.max_retries_down ||
          this.healthmonitor.expected_codes !== this.formData.expected_codes ||
          this.healthmonitor.url_path !== this.formData.url_path ||
          this.healthmonitor.http_method !== this.formData.http_method ||
          this.healthmonitor.timeout !== this.formData.timeout ||
          this.healthmonitor.delay !== this.formData.delay ||
          this.healthmonitor.max_retries !== this.formData.max_retries
        );
      else return false;
    },
    // images() {
    //   return this.$store.state.moduleStack.healthType.map(x => {
    //     return {
    //       name: x,
    //       id: x,
    //     };
    //   });
    // },
  },
  watch: {
    hasChanges: {
      handler: function (event) {
        // console.log(event);
      },
      immediate: true,
    },
    rule: {
      handler: async function (event) {
        if (event) {
          this.healthmonitor.type = event.protocol;
          await this.initFromData();
          this.isReady = !!event && !!Object.keys(event).length;
          this.loading = false;
        }
      },
      immediate: true,
    },
    healthmonitor: {
      handler: async function (event) {
        if (event && this.type === 'create') {
          this.prepareHealthMonitor(event, this.tumbler);
        }
      },
      immediate: true,
      deep: true,
    },
    tumbler: {
      handler: function (event) {
        if (event && this.type === 'create') {
          // console.log('tumbler--on');
          this.prepareHealthMonitor(this.healthmonitor, event);
        } else {
          // console.log('tumbler--off');
          this.prepareHealthMonitor(this.healthmonitor, event);
        }
      },
      immediate: true,
    },

    proxy: {
      handler: function (event) {},
      immediate: true,
    },
    protocol: {
      handler: function (event) {
        if (event) {
          if (event.includes('HTTP')) {
            this.healthmonitor.type = 'HTTP';
          } else if (event === 'TCP') {
            this.healthmonitor.type = 'TCP';
          } else if (event === 'UDP') {
            this.healthmonitor.type = 'UDP-CONNECT';
          }
        }
      },
      immediate: true,
    },
    isReady: {
      handler: function (event) {
        if (event && !this.loading) this.initFromData();
      },
      immediate: true,
    },
  },
  async mounted() {
    // if (this.isReady && this.loading) await this.initFromData();
    if (this.isReady && this.loading) await this.initFromData();
    // if (this.type === 'create') await this.initFromData();
  },
  methods: {
    prepareHealthMonitor(obj, tumbler) {
      const healthmonitor = Object.assign({}, obj);
      if (healthmonitor.type !== 'HTTP') {
        delete healthmonitor.http_method;
        delete healthmonitor.url_path;
        delete healthmonitor.expected_codes;
      }
      healthmonitor.admin_state_up = tumbler;
      this.$emit('add-check', { id: this.id, healthmonitor: healthmonitor });
    },
    async initFromData(updatedData) {
      // console.log('init');
      if (updatedData) {
        // console.log(new OPTariff(updatedData, 'healthmonitor'));
        this.healthmonitor = new OPTariff(updatedData, 'healthmonitor');
        this.tumbler = updatedData.admin_state_up;
      } else {
        this.healthmonitor = Object.assign(
          this.healthmonitor,
          await this.$store.state.moduleStack.healthmonitors.find(
            x => x.id === this.rule.healthmonitor_id
          )
        );
        this.tumbler = this.healthmonitor.admin_state_up;
      }
      this.items.forEach(el => {
        this.formData[el] = this.healthmonitor[el];
      });
      this.initialBackup = Object.assign({}, this.formData);
      this.loading = false;
    },
    setState(value) {
      this.tumbler = value; //===this.tumbler ?
      if (this.type === 'edit') {
        const payload = {
          healthmonitor: {
            admin_state_up: value,
          },
          id: this.rule.healthmonitor_id,
          item: 'admin_state_up',
        };
        this.$store.dispatch('moduleStack/updateHealthMonitor', payload);
      }
    },
    nameImage(name) {
      return this.$tc('addons', name);
      // return `${name}`;
    },
    refactorLabels(name) {
      return name.replace('_', '-');
      // return `${name}`;
    },
    onPeriodChange(event) {},
    onChange(item, event) {
      // console.log(event);
      this.formData[item] = event;
    },
    onChangeCheck(item, event) {
      // console.log(item, event);
      // this.$emit('change-check', { id: this.id, protocol: this.protocol });
      // this.formData.type = event;
    },
    updateHealthMonitor() {
      this.loading = true;
      this.isReady = false;
      const payload = {
        // id: this.healthmonitor.id,
        id: this.rule.healthmonitor_id,
        healthmonitor: {
          delay: this.healthmonitor.delay,
          timeout: this.healthmonitor.timeout,
          max_retries: this.healthmonitor.max_retries,
          max_retries_down: this.healthmonitor.max_retries_down,
        },
      };
      if (this.healthmonitor.http_method)
        payload.healthmonitor.http_method = this.healthmonitor.http_method;
      if (this.healthmonitor.expected_codes)
        payload.healthmonitor.expected_codes = this.healthmonitor.expected_codes;
      if (this.healthmonitor.url_path) payload.healthmonitor.url_path = this.healthmonitor.url_path;
      this.$store
        .dispatch('moduleStack/updateHealthMonitor', payload)
        .then(async data => {
          // console.log(data);

          await this.initFromData(data);
        })
        .catch(e => {
          this.showError(e.response.data.faultstring);

          this.healthmonitor = Object.assign(this.healthmonitor, this.initialBackup);
          this.isReady = true;
        })
        .finally(() => {
          this.loading = false;
          this.isReady = true;
        });
    },
    cancelHealthMonitor() {
      // this.healthmonitor = this.$store.state.moduleStack.healthmonitors.find(
      //   x => x.id === this.rule.healthmonitor_id
      // );
      this.healthmonitor = Object.assign(this.healthmonitor, this.initialBackup);
    },
  },
};
</script>
<i18n>
{
  "ru": {
    "sticky": "Sticky sessions",
    "addons": "0 подключений | 1 подключение | {n} подключения | {n} подключений",
    "text": "Балансировщик будет отслеживать состояние балансируемых серверов и перенаправит соединения только на доступные серверы.",
    "alg": {
      "title": "Проверки доступности",
      "atany": "Запросы распределяются на серверы по очереди в соответствии с заданным весом.",
      "atallow": "Новый запрос передаётся серверу с наименьшим количеством активных подключений, вес сервера не учитывается.",
      "sticky": "Новый запрос передаётся серверу с наименьшим количеством активных подключений, вес сервера не учитывается.",
      "ROUND_ROBIN": {
        "name": "Round Robin",
        "text": "Запросы распределяются на серверы по очереди в соответствии с заданным весом."
      },
      "LEAST_CONNECTIONS": {
        "name": "Least Connections",
        "text": "Запросы распределяются на серверы по очереди в соответствии с заданным весом."
      },
      "text": "Вам доступно создание 5 резервных копий. Свободно слотов – 1.Бэкапы можно создавать вручную или настроить расписание",
      "btn": {
        "save": "Подтвердить",
        "cancel": "Отменить"
      }
    }
  }
}


</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.select {
  width: 20rem;
}
  .bottom {
    margin-bottom: 0.5rem;
  }
.security-password {

  &__form {
    +breakpoint(sm-and-up) {
      flexy(flex-start, flex-end, wrap);
    }
    +breakpoint(md-and-up) {
      flex-wrap: nowrap;
      padding-bottom: 1rem;
    }
  }

  &__field {
    +breakpoint(sm-and-up) {
      //flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    }

    & + & {
      margin-top: 1.25rem;

      +breakpoint(sm-and-up) {
        margin-top: 0.5rem;
        margin-left: 1.5rem;
      }
    }
  }

  &__submit {
    width: 100%;
    white-space: nowrap;
    margin-top: 1.5rem;

    +breakpoint(sm-and-up) {
      width: auto;
    }
    +breakpoint(md-and-up) {
      margin-top: 0;
      margin-left: 1.5rem;
    }
  }
}
.addons {
  &__item {
    +breakpoint(sm-and-up) {
      flexy(flex-start, center);
    }

    & + & {
      margin-top: 1.5rem;

      +breakpoint(sm-and-up) {
        margin-top: 0.4rem;
        padding-top: 0.4rem;
        border-top: 1px solid var(--border);
      }
    }
  }

  &__label {
    +breakpoint(sm-and-up) {
      flex: 0 0 19rem;
      margin-right: 0.5rem;
    }
  }
}
.rule {
  width: 100%
  +breakpoint(sm-and-up) {
    flexy(flex-start, center);
  }
  &-block {
    &__content {
      //width: 100%;
      min-width: 60rem;
    }

    &__desc {
      //width: 100%;
      min-width: 50rem;
      //max-width: 50rem;

    }
    &__field {
      flex: 1 1 100%;
      margin: 0.5rem;

      +breakpoint(sm-and-up) {
        flex: 0 0 auto;
        margin: 0.75rem;
      }

      &--full {
        +breakpoint(sm-and-up) {
          flex: 0 1 100%;
        }
      }
      &--textarea {
        flex: 1 1 100%;

        +breakpoint(sm-and-up) {
          flex: 0 1 22rem;
        }
      }
    }

    &__actions {
      margin: 0.75rem -0.75rem -0.75rem;
      flexy(flex-start, center, wrap);
    }

    &__btn {
      margin: 0.75rem;

      +breakpoint(xs-only) {
        flex: 1 1 100%;
      }
    }
  }
  .vhost-detail {
    display: flex;
    flex-direction: column;
    min-size(100%);
    flex: 1 1 100%;

    &-view {
      &__block {
        //&--cards {
          max-width: 966px;
        //}
      }
    }
  }
}
</style>
