<template>
  <div class="rule">
    <!--    <page-block :title="$t('alg.title')" class="rule-block">-->
    <page-block title=" " class="rule-block">
      <base-loader v-if="(loading || !isReady) && type === 'edit'" class="base-btn__loader" />
      <main-card v-else>
        <div class="l-col">
          <label for="interval" class="base-select__label bottom medium-title">{{
            $t('text')
          }}</label>
        </div>
        <div class="security-password__form">
          <div class="security-password__field">
            <label for="interval" class="base-select__label bottom standart-title"
              >С интервалом</label
            >
            <InputNumber
              id="interval"
              v-model="listener.timeout_client_data"
              show-buttons
              mode="decimal"
              suffix=" мc"
              :min="0"
              :max="9999999"
              class="p-inputtext-lg"
              button-layout="horizontal"
              :input-style="{ 'text-align': 'center', 'max-width': '5rem' }"
              decrement-button-class="p-button-blue"
              increment-button-class="p-button-blue"
              increment-button-icon="pi pi-plus"
              decrement-button-icon="pi pi-minus"
              aria-describedby="value2-help"
            />
          </div>
          <!--              :custom-label="connectionView"-->
          <div class="security-password__field">
            <base-select
              v-model="listener.connection_limit"
              :options="connectionLimit"
              :searchable="false"
              :allow-empty="false"
              field-label="Количество соединений"
              class="select"
              :show-labels="false"
              @change="onPeriodChange('item', $event)"
            />
          </div>
          <div v-if="listener.connection_limit === 'ограничено'" class="security-password__field">
            <label for="timeout" class="base-select__label bottom standart-title"
              >Максимум соединений</label
            >
            <InputNumber
              id="timeout"
              v-model="connectionLimitBase"
              show-buttons
              mode="decimal"
              :min="1"
              class="p-inputtext-lg"
              :max="9999999"
              button-layout="horizontal"
              :input-style="{ 'text-align': 'center', 'max-width': '5rem' }"
              decrement-button-class="p-button-blue"
              increment-button-class="p-button-blue"
              increment-button-icon="pi pi-plus"
              decrement-button-icon="pi pi-minus"
              aria-describedby="value2-help"
            />
          </div>
        </div>
        <div class="l-col"></div>
        <div class="l-col">
          <label for="interval" class="base-select__label bottom medium-title">{{
            $t('textOut')
          }}</label>
        </div>
        <div class="security-password__form">
          <div class="security-password__field">
            <label class="base-select__label bottom standart-title">Таймаут соединения</label>
            <InputNumber
              v-model="listener.timeout_member_connect"
              show-buttons
              mode="decimal"
              suffix=" мc"
              :min="0"
              :max="9999999"
              class="p-inputtext-lg"
              button-layout="horizontal"
              :input-style="{ 'text-align': 'center', 'max-width': '5rem' }"
              decrement-button-class="p-button-blue"
              increment-button-class="p-button-blue"
              increment-button-icon="pi pi-plus"
              decrement-button-icon="pi pi-minus"
              aria-describedby="value2-help"
            />
          </div>
          <div class="security-password__field">
            <label class="base-select__label bottom standart-title">Таймаут неактивности</label>
            <InputNumber
              v-model="listener.timeout_member_data"
              show-buttons
              mode="decimal"
              suffix=" мc"
              :min="0"
              class="p-inputtext-lg"
              :max="9999999"
              button-layout="horizontal"
              :input-style="{ 'text-align': 'center', 'max-width': '5rem' }"
              decrement-button-class="p-button-blue"
              increment-button-class="p-button-blue"
              increment-button-icon="pi pi-plus"
              decrement-button-icon="pi pi-minus"
              aria-describedby="value2-help"
            />
          </div>
          <div class="security-password__field">
            <label class="base-select__label bottom standart-title">Таймаут ожидания</label>
            <InputNumber
              v-model="listener.timeout_tcp_inspect"
              show-buttons
              mode="decimal"
              suffix=" мc"
              :min="0"
              class="p-inputtext-lg"
              :max="9999999"
              button-layout="horizontal"
              :input-style="{ 'text-align': 'center', 'max-width': '5rem' }"
              decrement-button-class="p-button-blue"
              increment-button-class="p-button-blue"
              increment-button-icon="pi pi-plus"
              decrement-button-icon="pi pi-minus"
              aria-describedby="value2-help"
            />
          </div>
        </div>
        <div
          v-if="hasChanges && !hideButtons && type === 'edit'"
          slot="footerEnd"
          class="rule-block__actions"
          @click="updateListner"
        >
          <base-button class="rule-block__btn">
            {{ $t('alg.btn.save') }}
          </base-button>
          <base-button theme="outlined" class="rule-block__btn" @click="clearData">
            {{ $t('alg.btn.cancel') }}
          </base-button>
        </div>
      </main-card>
    </page-block>
  </div>
</template>

<script>
import BaseSelect from '@/components/Select/BaseSelect';
import InputNumber from 'primevue/inputnumber';
export default {
  name: 'RuleConnect',
  components: { InputNumber, /*BaseCheckbox*/ BaseSelect },
  // mixins: [specsFormatting],
  props: {
    ruleid: {
      type: String,
      default: '',
    },
    dataset: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: 'edit',
      validator: val => ['edit', 'create'].includes(val),
    },
    id: {
      type: Number,
      default: 0,
    },
    rule: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      initialBackup: {},
      isReady: false,
      loading: true,
      hideButtons: true,
      hasChangesConnectLimit: false,
      hasChangesConnectLimitData: false,
      hasChangesTimeoutClientData: false,
      hasChangesTimeoutMemberConnect: false,
      hasChangesTimeoutMemberData: false,
      hasChangesTcpInspect: false,
      formData: {},
      items: [
        'timeout_client_data',
        'timeout_member_connect',
        'timeout_member_data',
        'timeout_tcp_inspect',
        'connection_limit',
      ],
      connectionLimitBase: 10000,
      listener: {
        timeout_client_data: 50000,
        timeout_member_connect: 50000,
        timeout_member_data: 50000,
        timeout_tcp_inspect: 0,
        connection_limit: 'не ограничено',
      },
      connectionLimit: ['ограничено', 'не ограничено'],
    };
  },
  computed: {
    hasChanges() {
      return (
        this.hasChangesConnectLimit ||
        this.hasChangesTcpInspect ||
        this.hasChangesTimeoutMemberData ||
        this.hasChangesTimeoutClientData ||
        this.hasChangesConnectLimitData ||
        this.hasChangesTimeoutMemberConnect
      );
    },
  },
  watch: {
    rule: {
      handler: function (event) {
        // console.log(event);
        this.isReady = !!event && !!Object.keys(event).length;
        if (this.isReady) this.initFromData();
        // console.log(Object.keys(e
      },
      immediate: true,
    },
    isReady: {
      handler: function (event) {
        // console.log(this.loading);
        // if (event && !this.loading) this.initFromData();
        // console.log(Object.keys(event));
      },
      immediate: true,
    },
    'listener.connection_limit': {
      handler: function (event) {
        // console.log(event);
        this.hasChangesConnectLimit = event !== this.formData.connectionLimit;
        this.hideButtons = event === this.formData.connectionLimit;
      },
      // immediate: true,
      immediate: false,
    },
    'listener.timeout_tcp_inspect': {
      handler: function (event) {
        this.hasChangesTcpInspect = event !== this.formData.timeout_tcp_inspect;
        this.hideButtons = event === this.formData.timeout_tcp_inspect;
      },
      // immediate: true,
      immediate: false,
    },
    connectionLimitBase: {
      handler: function (event) {
        if (this.formData.connection_limit && this.formData.connection_limit > 0)
          this.hasChangesConnectLimitData = event !== this.formData.connection_limit;
        this.hideButtons = event === this.formData.connection_limit;
        if (event && this.type === 'create') {
          const listener = Object.assign({}, this.listener);
          listener.connection_limit = event;
          this.$emit('add-connect', { id: this.id, listener: listener });
        }
      },
      immediate: true,
    },
    'listener.timeout_member_data': {
      handler: function (event) {
        this.hasChangesTimeoutMemberData = event !== this.formData.timeout_member_data;
        this.hideButtons = event === this.formData.timeout_member_data;
      },
      immediate: false,
    },
    'listener.timeout_member_connect': {
      handler: function (event) {
        this.hasChangesTimeoutMemberConnect = event !== this.formData.timeout_member_connect;
        this.hideButtons = event === this.formData.timeout_member_connect;
      },
      immediate: false,
    },
    'listener.timeout_client_data': {
      handler: function (event) {
        this.hasChangesTimeoutClientData = event !== this.formData.timeout_client_data;
        this.hideButtons = event === this.formData.timeout_client_data;
      },
      immediate: false,
    },
    listener: {
      handler: function (event) {
        if (event && this.type === 'create') {
          const listener = Object.assign({}, event);
          listener.connection_limit =
            listener.connection_limit === 'не ограничено' ? -1 : this.connectionLimitBase;
          this.$emit('add-connect', { id: this.id, listener: listener });
        }
      },
      deep: true,
      immediate: true,
    },
  },
  async mounted() {
    if (this.isReady && this.loading) await this.initFromData();
  },
  methods: {
    async initFromData() {
      // console.log('init');
      this.loading = true;
      this.listener = await this.$store.state.moduleStack.listeners.find(
        x => x.id === this.rule.listeners[0].id
      );
      this.formData.timeout_client_data = this.listener.timeout_client_data;
      this.formData.timeout_member_connect = this.listener.timeout_member_connect;
      this.formData.timeout_member_data = this.listener.timeout_member_data;
      this.formData.timeout_tcp_inspect = this.listener.timeout_tcp_inspect;
      this.formData.connection_limit = this.listener.connection_limit;
      if (this.listener.connection_limit > 0)
        this.connectionLimitBase = this.listener.connection_limit;
      this.formData.connectionLimit =
        this.listener.connection_limit > 0 ? 'ограничено' : 'не ограничено';
      this.listener.connection_limit = this.formData.connectionLimit;
      this.clearData();
      this.initialBackup = Object.assign({}, this.formData);
      this.loading = false;
    },
    onPeriodChange(item, event) {
      console.log(item, event);
    },
    clearData() {
      this.hasChangesConnectLimit = false;
      this.hasChangesConnectLimitData = false;
      this.hasChangesTimeoutClientData = false;
      this.hasChangesTimeoutMemberConnect = false;
      this.hasChangesTimeoutMemberData = false;
      this.hasChangesTcpInspect = false;

      this.listener.timeout_client_data = this.formData.timeout_client_data;
      this.listener.timeout_member_connect = this.formData.timeout_member_connect;
      this.listener.timeout_member_data = this.formData.timeout_member_data;
      this.listener.timeout_tcp_inspect = this.formData.timeout_tcp_inspect;
      this.listener.connection_limit = this.formData.connectionLimit;
      if (
        this.connectionLimitBase !== this.formData.connection_limit &&
        this.formData.connectionLimit === 'ограничено'
      )
        this.connectionLimitBase = this.formData.connection_limit;
    },
    // connectionView(item) {
    //   console.log(item);
    //   return item === -1 ? 'не ограничено' : 'ограничено';
    // },
    updateListner() {
      this.loading = true;
      const params = {
        id: this.listener.id,
        listener: {
          timeout_client_data: this.listener.timeout_client_data,
          timeout_member_connect: this.listener.timeout_member_connect,
          timeout_member_data: this.listener.timeout_member_data,
          timeout_tcp_inspect: this.listener.timeout_tcp_inspect,
          connection_limit:
            this.listener.connection_limit === 'ограничено' ? this.connectionLimitBase : -1,
        },
      };
      this.$store
        .dispatch('moduleStack/updateListener', params)
        .then(data => {
          console.log(data);
          this.initFromData();
        })
        .catch(() => {
          this.listener = Object.assign(this.listener, this.initialBackup);
        })
        .finally(() => {
          this.loading = false;
          this.clearData();
        });
    },
  },
};
</script>
<i18n>
{
  "ru": {
    "sticky": "Sticky sessions",
    "addons": "0 подключений | 1 подключение | {n} подключения | {n} подключений",
    "text": "Входящие запросы -> балансировщик",
    "textOut": "Балансировщик -> серверы",
    "alg": {
      "title": "Входящие запросы -> балансировщик",
      "atany": "Запросы распределяются на серверы по очереди в соответствии с заданным весом.",
      "atallow": "Новый запрос передаётся серверу с наименьшим количеством активных подключений, вес сервера не учитывается.",
      "sticky": "Новый запрос передаётся серверу с наименьшим количеством активных подключений, вес сервера не учитывается.",
      "ROUND_ROBIN": {
        "name": "Round Robin",
        "text": "Запросы распределяются на серверы по очереди в соответствии с заданным весом."
      },
      "LEAST_CONNECTIONS": {
        "name": "Least Connections",
        "text": "Запросы распределяются на серверы по очереди в соответствии с заданным весом."
      },
      "text": "Вам доступно создание 5 резервных копий. Свободно слотов – 1.Бэкапы можно создавать вручную или настроить расписание",
      "btn": {
        "save": "Подтвердить",
        "cancel": "Отменить"
      }
    }
  }
}


</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.select {
  width: 20rem;
}
  .bottom {
    margin-bottom: 0.5rem;
  }
.security-password {

  &__form {
    +breakpoint(sm-and-up) {
      flexy(flex-start, flex-end, wrap);
    }
    +breakpoint(md-and-up) {
      flex-wrap: nowrap;
      padding-bottom: 1rem;
    }
  }

  &__field {
    +breakpoint(sm-and-up) {
      //flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    }

    & + & {
      margin-top: 1.25rem;

      +breakpoint(sm-and-up) {
        margin-top: 0.5rem;
        margin-left: 1.5rem;
      }
    }
  }

  &__submit {
    width: 100%;
    white-space: nowrap;
    margin-top: 1.5rem;

    +breakpoint(sm-and-up) {
      width: auto;
    }
    +breakpoint(md-and-up) {
      margin-top: 0;
      margin-left: 1.5rem;
    }
  }
}
.addons {
  &__item {
    +breakpoint(sm-and-up) {
      flexy(flex-start, center);
    }

    & + & {
      margin-top: 1.5rem;

      +breakpoint(sm-and-up) {
        margin-top: 0.4rem;
        padding-top: 0.4rem;
        border-top: 1px solid var(--border);
      }
    }
  }

  &__label {
    +breakpoint(sm-and-up) {
      flex: 0 0 19rem;
      margin-right: 0.5rem;
    }
  }
}
.rule {
  width: 100%
  +breakpoint(sm-and-up) {
    flexy(flex-start, center);
  }
  &-block {
    &__content {
      //width: 100%;
      min-width: 60rem;
    }

    &__desc {
      //width: 100%;
      min-width: 50rem;
      //max-width: 50rem;

    }
    &__field {
      flex: 1 1 100%;
      margin: 0.5rem;

      +breakpoint(sm-and-up) {
        flex: 0 0 auto;
        margin: 0.75rem;
      }

      &--full {
        +breakpoint(sm-and-up) {
          flex: 0 1 100%;
        }
      }
      &--textarea {
        flex: 1 1 100%;

        +breakpoint(sm-and-up) {
          flex: 0 1 22rem;
        }
      }
    }

    &__actions {
      margin: 0.75rem -0.75rem -0.75rem;
      flexy(flex-start, center, wrap);
    }

    &__btn {
      margin: 0.75rem;

      +breakpoint(xs-only) {
        flex: 1 1 100%;
      }
    }
  }
  .vhost-detail {
    display: flex;
    flex-direction: column;
    min-size(100%);
    flex: 1 1 100%;

    &-view {
      &__block {
        //&--cards {
          max-width: 966px;
        //}
      }
    }
  }
}
</style>
