<template>
  <div class="resize">
    <div v-if="!!newPorts.length" class="l-col">
      <label class="typo__label standart-text paragraf">{{ $t('label.server') }}</label>
      <base-select
        v-model="server"
        select-label=""
        :hide-selected="true"
        :searchable="false"
        :options="newPorts"
        track-by="port"
        :custom-label="nameServer"
        size="medium"
        :required="true"
        :allow-empty="false"
        :preselect-first="true"
        class="paragraf-up"
        @input="onChange('server', $event)"
      >
      </base-select>
    </div>
    <div v-else class="l-col">
      <label class="typo__label section-header standart-text">
        <p v-html="$t('empty', { subnet: subnetName })"></p>
      </label>
    </div>
    <div v-if="!!newPorts.length" class="l-col">
      <label class="typo__label standart-text paragraf">{{ $t('label.port') }}</label>
      <base-input
        v-model="port"
        required
        :placeholder="$t('disk.placeholder')"
        class="paragraf-up"
        @input="onChange('port', $event)"
      >
      </base-input>
    </div>
    <div v-if="!isCorrectProtocol" class="l-col">
      <label class="typo__label section-header standart-title error-color">
        {{ $t('label.error') }}</label
      >
    </div>
    <div v-if="!!newPorts.length" class="resize-row">
      <div class="resize-row-text standart-text">{{ $t('label.weight') }}</div>
      <div class="resize-row-input">
        <InputNumber
          v-model="weight"
          show-buttons
          mode="decimal"
          :min="1"
          :max="256"
          button-layout="horizontal"
          :input-style="{ 'text-align': 'center', width: '6rem', minWidth: '6rem' }"
          decrement-button-class="p-button-blue"
          increment-button-class="p-button-blue"
          increment-button-icon="pi pi-plus"
          decrement-button-icon="pi pi-minus"
          aria-describedby="value2-help"
          @input="onChange('weight', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import setFocus from '@/mixins/setFocus';
import BaseSelect from '@/components/Select/BaseSelect';
import BaseInput from '@/components/BaseInput/BaseInput.vue';
import { dev } from '@/layouts/LoginPage/mixins/currentProvider';
import InputNumber from 'primevue/inputnumber';
export default {
  name: 'NewMember',
  components: { BaseInput, BaseSelect, InputNumber },
  mixins: [setFocus],
  props: {
    members: {
      type: Array,
      default: () => [],
    },
    ports: {
      type: Array,
      default: () => [],
    },
    ruleId: {
      type: String,
      default: '',
    },
    subnetId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      port: '80',
      volume: [],
      ip: [],
      weight: 1,
      name: '',
      checkedPort: {},
      description: '',
      server: [],
      isCorrectProtocol: true,
      image: [],
      formData: {
        diskName: '',
        name: '',
        id: '',
        type: 'SSD',
        func: '',
      },
    };
  },
  computed: {
    options() {
      return [
        { from: 'none', name: 'чистый' },
        { from: 'snapshot', name: 'снимок' },
        { from: 'image', name: 'образ' },
        { from: 'disk', name: 'диск' },
      ];
    },
    subnetName() {
      return this.$store.state.moduleStack.subnets.find(x => x.id === this.subnetId).name;
    },
    newPorts() {
      const ports = [];
      const members = this.members.map(x => x.address);
      this.ports.forEach(port => {
        port.port.forEach(el => {
          if (el.subnet_id === this.subnetId && !members.includes(el.ip_address)) {
            ports.push({
              network: port.network,
              server: port.server,
              port: el,
            });
          }
        });
      });
      return ports;
    },
  },
  watch: {
    'server.port': {
      handler: function (event) {
        // console.log(event.subnet_id, event.ip_address, this.port);
        if (event) {
          // console.log(event);
          const check = this.members.find(
            x => x.address === event.ip_address && x.subnet_id === event.subnet_id
          );
          // console.log(check);
          if (check && check.protocol_port) {
            this.checkedPort = check;
            this.isCorrectProtocol = check.protocol_port !== +this.port;
          } else {
            this.isCorrectProtocol = true;
            this.checkedPort = {};
          }
        }
      },
    },
    'checkedPort.protocol_port': {
      handler: function (event) {
        if (event) {
          this.isCorrectProtocol = event !== +this.port;
        } else this.isCorrectProtocol = true;
      },
    },
    port(event) {
      if (event && Object.keys(this.checkedPort).length > 0) {
        if (+event === this.checkedPort.protocol_port) {
          this.isCorrectProtocol = +event !== this.checkedPort.protocol_port;
          // console.log(event);
        } else {
          this.isCorrectProtocol = true;
          // this.$emit('change', {
          //   protocol_port: this.port,
          // });
        }
      }
    },
    isCorrectProtocol: {
      handler: function (event) {
        if (event) {
          this.$emit('change', {
            name: this.$store.state.moduleStack.servers.find(x => x.id === this.server.server).name,
            protocol_port: this.port,
            subnet_id: this.server.port.subnet_id,
            address: this.server.port.ip_address,
            weight: this.weight,
            tags: [this.server.server],
          });
        } else this.$emit('notready');
      },
      immediate: false,
    },
  },
  mounted() {
    if (this.isCorrectProtocol && !!this.newPorts.length)
      this.$emit('change', {
        name: this.$store.state.moduleStack.servers.find(x => x.id === this.server.server).name,
        protocol_port: this.port,
        subnet_id: this.server.port.subnet_id,
        address: this.server.port.ip_address,
        weight: this.weight,
        tags: [this.server.server],
      });
    else this.$emit('notready');
  },
  methods: {
    // },
    nameDisk({ name, size }) {
      return `${name} (${size}ГБ)`;
    },
    nameSnapshot({ name, size }) {
      return `${name} (${size}ГБ)`;
    },
    nameImage({ name, size }) {
      return `${name} (${size}ГБ)`;
    },
    nameServer({ server, port }) {
      // console.log(server, port);
      const id = server;
      // console.log(this.$store.state.moduleStack.servers.find(x => x.id === id));
      // const ip = port[0].ip_address;
      const ip = port.ip_address;
      // console.log(ip);
      const name =
        this.$store.state.moduleStack.servers.find(x => x.id === id).name ||
        this.$store.state.moduleStack.servers.find(x => x.id === id).id;
      // console.log(name);

      return `${name} IP-адрес: ${ip}`;
    },
    nameType({ name }) {
      return `${name}`;
    },
    onChange(item, event) {
      // console.log(item, event);
      if (this.isCorrectProtocol)
        this.$emit('change', {
          name: this.$store.state.moduleStack.servers.find(x => x.id === this.server.server).name,
          protocol_port: this.port,
          // subnet_id: this.server.port[0].subnet_id,
          subnet_id: this.server.port.subnet_id,
          // address: this.server.port[0].ip_address,
          address: this.server.port.ip_address,
          weight: this.weight,
          tags: [this.server.server],
        });
      else this.$emit('notready');
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "label": {
      "server": "Сервер:",
      "port": "Порт:",
      "error": "Порт занят, пожалуйста выберите другой.",
      "weight": "Вес:",
      "description": "Описание",
      "image" : "Использовать образ как источник",
      "disks" : "Использовать диск как ресурс",
      "snap": "Использовать снимок как источник"
    },
    "empty": "В подсети <b>%{subnet}</b> нет серверов.",
    "disk": {
      "label": "Имя диска",
      "placeholder": "Порт",
      "description" : "Текстовое описание"
    },
    "sure": {
      "confirm": "Увеличить"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.paragraf {
  margin-bottom: 10px;
  &-up{
    margin-top: 10px;

  }
}
.resize {
  text-overflow: unset;
  overflow: visible;

  &-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0.5rem 0.5rem;
    padding: 0 15px 20px
&-input {
  padding: 1.5rem 1.5rem;
}
  }
}
</style>
