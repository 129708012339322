<template>
  <div class="rule">
    <page-block :title="$t('alg.title')" class="rule-block">
      <base-loader v-if="(loading || !isReady) && type === 'edit'" class="base-btn__loader" />
      <main-card v-else>
        <div class="l-col">
          <label class="base-select__label">{{ $t('text') }}</label>
        </div>
        <div class="l-col">
          <base-checkbox
            :value="insert_headers['X-Forwarded-For']"
            true-value="true"
            false-value="false"
            @change="onChange('X-Forwarded-For', $event)"
          >
            {{ $t('for') }}
          </base-checkbox>
        </div>
        <div class="l-col">
          <base-checkbox
            :value="insert_headers['X-Forwarded-Port']"
            true-value="true"
            false-value="false"
            @change="onChange('X-Forwarded-Port', $event)"
          >
            {{ $t('port') }}
          </base-checkbox>
        </div>
        <div class="l-col">
          <base-checkbox
            :value="insert_headers['X-Forwarded-Proto']"
            true-value="true"
            false-value="false"
            @change="onChange('X-Forwarded-Proto', $event)"
          >
            {{ $t('proto') }}
          </base-checkbox>
        </div>

        <div
          v-if="hasChanges && type === 'edit'"
          slot="footerEnd"
          class="rule-block__actions"
          @click="updateHeaders"
        >
          <base-button class="rule-block__btn">
            {{ $t('alg.btn.save') }}
          </base-button>
          <base-button theme="outlined" class="rule-block__btn" @click="clearData">
            {{ $t('alg.btn.cancel') }}
          </base-button>
        </div>
      </main-card>
    </page-block>
  </div>
</template>

<script>
import BaseSelect from '@/components/Select/BaseSelect';
import InputNumber from 'primevue/inputnumber';
import BaseInput from '@/components/BaseInput/BaseInput';
import BaseCheckbox from '@/components/Checkboxes/BaseCheckbox';
import BaseLoader from '@/components/BaseLoader/BaseLoader';
export default {
  name: 'RuleHeaders',
  components: {
    BaseCheckbox,
    BaseLoader,
  },
  props: {
    ruleid: {
      type: String,
      default: '',
    },
    id: {
      type: Number,
      default: 0,
    },
    type: {
      type: String,
      default: 'edit',
      validator: val => ['edit', 'create'].includes(val),
    },
    dataset: {
      type: Array,
      default: () => [],
    },
    rule: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      backupData: {},
      loading: true,
      isReady: false,
      insert_headers: {
        'X-Forwarded-For': 'false',
        'X-Forwarded-Port': 'false',
        'X-Forwarded-Proto': 'false',
      },
      listener: {},
    };
  },
  computed: {
    hasChanges() {
      if (
        this.type === 'edit' &&
        this.insert_headers &&
        Object.keys(this.insert_headers).length > 0
      ) {
        return (
          this.insert_headers['X-Forwarded-For'] !==
            this.backupData.insert_headers['X-Forwarded-For'] ||
          this.insert_headers['X-Forwarded-Port'] !==
            this.backupData.insert_headers['X-Forwarded-Port'] ||
          this.insert_headers['X-Forwarded-Proto'] !==
            this.backupData.insert_headers['X-Forwarded-Proto']
        );
      } else return false;
    },
  },
  watch: {
    rule: {
      handler: function (event) {
        console.log(event);
        this.isReady = !!event && !!Object.keys(event).length;
        if (this.isReady) this.init();
      },
      immediate: true,
    },
    insert_headers: {
      handler: function (event) {
        if (event && this.type === 'create') {
          this.$emit('add-headers', { id: this.id, headers: this.insert_headers });
          // console.log(event);
        }
      },
      deep: true,
      immediate: true,
    },
    isReady: {
      handler: function (event) {
        console.log('isReady', this.loading, event);
      },
      immediate: true,
    },
  },
  // mounted(){
  //
  // },
  async mounted() {
    if (this.isReady && this.loading) await this.init();
  },
  methods: {
    async init() {
      this.loading = true;
      if (
        this.rule &&
        this.rule.listeners &&
        this.rule.listeners.length &&
        this.rule.listeners[0].id
      )
        this.listener = await this.$store.state.moduleStack.listeners.find(
          x => x.id === this.rule.listeners[0].id
        );
      if (this.type === 'create') {
        this.backupData.insert_headers = Object.assign({}, this.insert_headers);
        this.insert_headers = Object.assign({}, this.insert_headers);
      } else {
        this.backupData.insert_headers = Object.assign({}, this.listener.insert_headers);
        this.insert_headers = Object.assign({}, this.listener.insert_headers);
      }
      this.loading = false;
    },
    clearData() {
      this.insert_headers = Object.assign({}, this.backupData.insert_headers);
    },
    updateHeaders() {
      this.loading = true;
      const params = {
        id: this.listener.id,
        listener: {
          insert_headers: this.insert_headers,
        },
      };
      this.$store
        .dispatch('moduleStack/updateListener', params)
        .then(() => {})
        .catch(() => {
          this.clearData();
        })
        .finally(() => {
          this.loading = false;
          this.init();
        });
    },
    onChange(name, value) {
      this.insert_headers[name] = value;
    },
  },
};
</script>
<i18n>
{
  "ru": {
    "for": "X-Forwarded-For",
    "port": "X-Forwarded-Port",
    "proto": "X-Forwarded-Proto",
    "addons": "0 подключений | 1 подключение | {n} подключения | {n} подключений",
    "text": "Дополнительные заголовки, которые будут добавлены в запрос, чтобы серверы получали эту информацию для корректной работы или анализа.",
    "textOut": "Балансировщик -> серверы.",
    "alg": {
      "title": "Алгоритм распределения запросов",
      "atany": "Запросы распределяются на серверы по очереди в соответствии с заданным весом.",
      "atallow": "Новый запрос передаётся серверу с наименьшим количеством активных подключений, вес сервера не учитывается.",
      "sticky": "Новый запрос передаётся серверу с наименьшим количеством активных подключений, вес сервера не учитывается.",
      "ROUND_ROBIN": {
        "name": "Round Robin",
        "text": "Запросы распределяются на серверы по очереди в соответствии с заданным весом."
      },
      "LEAST_CONNECTIONS": {
        "name": "Least Connections",
        "text": "Запросы распределяются на серверы по очереди в соответствии с заданным весом."
      },
      "text": "Вам доступно создание 5 резервных копий. Свободно слотов – 1.Бэкапы можно создавать вручную или настроить расписание",
      "btn": {
        "save": "Подтвердить",
        "cancel": "Отменить"
      }
    }
  }
}


</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.select {
  width: 20rem;
}
  .bottom {
    margin-bottom: 0.5rem;
  }
.security-password {

  &__form {
    +breakpoint(sm-and-up) {
      flexy(flex-start, flex-end, wrap);
    }
    +breakpoint(md-and-up) {
      flex-wrap: nowrap;
      padding-bottom: 1rem;
    }
  }

  &__field {
    +breakpoint(sm-and-up) {
      //flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    }

    & + & {
      margin-top: 1.25rem;

      +breakpoint(sm-and-up) {
        margin-top: 0.5rem;
        margin-left: 1.5rem;
      }
    }
  }

  &__submit {
    width: 100%;
    white-space: nowrap;
    margin-top: 1.5rem;

    +breakpoint(sm-and-up) {
      width: auto;
    }
    +breakpoint(md-and-up) {
      margin-top: 0;
      margin-left: 1.5rem;
    }
  }
}
.addons {
  &__item {
    +breakpoint(sm-and-up) {
      flexy(flex-start, center);
    }

    & + & {
      margin-top: 1.5rem;

      +breakpoint(sm-and-up) {
        margin-top: 0.4rem;
        padding-top: 0.4rem;
        border-top: 1px solid var(--border);
      }
    }
  }

  &__label {
    +breakpoint(sm-and-up) {
      flex: 0 0 19rem;
      margin-right: 0.5rem;
    }
  }
}
.rule {
  width: 100%
  +breakpoint(sm-and-up) {
    flexy(flex-start, center);
  }
  &-block {
    &__content {
      //width: 100%;
      min-width: 60rem;
    }

    &__desc {
      //width: 100%;
      min-width: 50rem;
      //max-width: 50rem;

    }
    &__field {
      flex: 1 1 100%;
      margin: 0.5rem;

      +breakpoint(sm-and-up) {
        flex: 0 0 auto;
        margin: 0.75rem;
      }

      &--full {
        +breakpoint(sm-and-up) {
          flex: 0 1 100%;
        }
      }
      &--textarea {
        flex: 1 1 100%;

        +breakpoint(sm-and-up) {
          flex: 0 1 22rem;
        }
      }
    }

    &__actions {
      margin: 0.75rem -0.75rem -0.75rem;
      flexy(flex-start, center, wrap);
    }

    &__btn {
      margin: 0.75rem;

      +breakpoint(xs-only) {
        flex: 1 1 100%;
      }
    }
  }
  .vhost-detail {
    display: flex;
    flex-direction: column;
    min-size(100%);
    flex: 1 1 100%;

    &-view {
      &__block {
        //&--cards {
          max-width: 966px;
        //}
      }
    }
  }
}
</style>
