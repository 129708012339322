import NewMember from '@/layouts/Stack/components/NewMember';
import showErrorModal from '@/mixins/showErrorModal';
// import storeMixin from '@/layouts/Stack/mixins/index';
import Vue from 'vue';
import modals from '@/mixins/modals';

export default {
  mixins: [showErrorModal, modals /*, storeMixin*/],
  data() {
    return {
      data: {},
      timerId: '',
      // ruleName: 'wlkejrlwkejr',
      timerCnt: 0,
      isProcessing: false,
      member: {},
      // id: this.ruleId,
    };
  },
  computed: {
    quota() {
      return this.$store.getters['moduleStack/aviableSpaseVolume'];
    },
    projectId() {
      return this.$store.getters['moduleStack/projectId'];
    },
  },
  methods: {
    newMember: function () {
      const that = this;
      return new Promise(() => {
        const selfName = 'NewMember';
        this.$modals.open({
          name: selfName,
          size: 'medium',
          component: NewMember,
          closeOnBackdrop: false,
          props: {
            members: this.members,
            ruleId: this.ruleid,
            ports: this.ports,
            subnetId: this.subnetId,
          },
          text: this.$t('add'),
          on: {
            change: data => {
              this.member = data;
              // console.log(data);
              Vue.set(this.modal.footer.confirm.props, 'disabled', false);
            },
            notready: () => {
              Vue.set(this.modal.footer.confirm.props, 'disabled', true);
            },
          },
          onOpen: inst => (this.modal = inst),
          onClose: () => (this.modal = null),
          onDismiss: () => (this.modal = null),
          footer: {
            // centered: true,
            confirm: {
              props: { title: this.$t('confirm') },
              on: {
                click: () => {
                  Vue.set(this.modal, 'footer', {
                    confirm: {
                      props: { loading: true },
                    },
                  });
                  // console.log(this.member);
                  let name = this.member.name;
                  this.addNewMember(this.member)
                    .then(async data => {
                      // console.log(data);
                      if (data) {
                        await this.fetchMembers();
                        that.$modals.close();
                        this.showResModal(
                          this.$t('success', { server: name, rule: this.rule.name })
                        );
                        // this.showResModal('Сервер добавлен в правило');
                      }
                      Vue.set(that.modal, 'footer', {
                        confirm: {
                          props: { loading: false },
                        },
                      });

                      // this.data = { disk: data.volume.id };
                      // console.log(this.data);
                    })
                    .catch(e => {
                      console.log(e);
                      Vue.set(that.modal, 'footer', {
                        confirm: {
                          props: { loading: false },
                        },
                      });
                      that.$modals.close();
                      // this.$modals.close();
                      this.showResModal(e);
                      // this.showError(e);
                    });
                  // .finally(() => {
                  //   that.$modals.close();
                  // });
                  // },
                },
              },
            },
            cancel: {
              on: {
                click: () => {
                  that.$modals.close();
                },
              },
            },
          },
        });
      });
    },
    addNewMember(payload) {
      // console.log(payload);
      return this.$store
        .dispatch('moduleStack/addMember', {
          member: payload,
          id: this.ruleid,
        })
        .catch(e => {
          console.log(e);
          this.showError(e);
        });
    },
    async fetchMembers() {
      await this.$store
        .dispatch('moduleStack/getPoolMembers', { id: this.ruleid })
        .then(async data => {
          // console.log(data);
          this.member = data ? data : [];
        });
    },
    // makeModal(props = {}) {
    //   this.$modals.open({
    //     name: 'AddModal',
    //     size: 'small',
    //     onOpen: inst => (this.modal = inst),
    //     onClose: () => (this.modal = null),
    //     onDismiss: () => (this.modal = null),
    //     ...props,
    //   });
    // },
    // showResModal(res, props = {}) {
    //   if (!this.modal) this.makeModal(props);
    //   Vue.set(this.modal, 'component', null);
    //   Vue.set(this.modal, 'closable', true);
    //   Vue.set(this.modal, 'text', res);
    //   Vue.set(this.modal, 'footer', {
    //     confirm: {
    //       on: {
    //         click: () => {
    //           this.$modals.close();
    //         },
    //       },
    //     },
    //   });
    // },
  },
};
